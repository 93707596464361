import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { StaticQuery, graphql } from 'gatsby';

/**
 * Components
 */
import Relative from '@components/relative/relative';
import SeamlessExperienceWithServices from '@components/seamless-experience/seamless-experience-with-service-links';
import BackgroundImage from 'gatsby-background-image-es5';

const AllAreaGuides = (props) => {
  return (
    <>
      {props.data.allStrapiAreaGuides.edges.map(({node}, key) => {
        if(key % 2 == 0) {
          var alignment = "Left"
        } else {
          var alignment = "Right"
        }
        return <div className="areaguide-landing-page">
                  <SeamlessExperienceWithServices Title={node.Title} Content={node.Short_Description} Embed_Video_URL={node.Embed_Video_URL} CTA_Label={`Explore ${node.Title}`}  CTA_URL={`${props.parent}/${props.page}/${node.URL}`} Upload_Video={node.Upload_Video} Image={node.Tile_Image} ServiceLinks={node.Services} Image_Alignment={alignment} size="780x600"/>
        </div>
      })}
    </>
  )
}

export default function AreaGuidesList(props) {
  return (
    <StaticQuery
      query={graphql`
      query {
        allStrapiAreaGuides(filter: {Publish: {eq: true}}) {
          edges {
            node {
              URL
              Title
              Short_Description
              Tile_Image {
                internal {
                  description
                }
                publicURL
              }
              Upload_Video {
                publicURL
              }
              Services {
                Label
                URL
              }
              Embed_Video_URL
            }
          }
        }
      }
    `}
      render={data => <AllAreaGuides data={data} parent={props.Parent} page={props.Page}/>}
    />
  )
}