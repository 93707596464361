import React from 'react';
import { Container, Row, Col, Form, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { StaticQuery, graphql } from 'gatsby';
import { Link } from "gatsby";

/**
 * Components
 */
import Relative from '@components/relative/relative';
import LoadMorePagination from '@components/load-more-pagination/load-more-pagination';

const AllNewsList = (props) => {
  return (
    <>
      <Container>

      <div className="news-filters news-filter-desktop">
        <Link to="" className="btn btn-outline-primary cta active">All Posts</Link>
        {props.data.allStrapiNewsCategories.edges.map(({node}, key) => {
          return <>
          {node.news_events.length != 0 &&
          <Link to={`../${node.URL}`} className="btn btn-outline-primary cta">{node.Category_Name}</Link>
          }
          </>
        })}
      </div>

      <div className="news-filters news-filter-mobile">
      <DropdownButton id="dropdown-basic-button" title="All Posts">
        {props.data.allStrapiNewsCategories.edges.map(({node}, key) => {
          return <>
          {node.news_events.length != 0 &&
            <Dropdown.Item><Link to={`../${node.URL}`}>{node.Category_Name}</Link></Dropdown.Item>
          }
          </>
        })}
      </DropdownButton>
      </div>


        <LoadMorePagination content={props.data.allStrapiNewsEvents.edges} path="/about-us/property-news" LoadMore="Load More" />

      </Container>
    </>
  )
}

export default function AllNews(props) {
  return (
    <StaticQuery
      query={graphql`
      query {
        allStrapiNewsEvents(filter: {Publish: {eq: true}}, sort: {fields: Date, order: DESC}) {
          edges {
            node {
              Title
              URL
              Tile_Image {
                internal {
                  description
                }
                publicURL
              }
              Date(formatString: "Do MMMM, YYYY")
              news_category {
                URL
              }
              Upload_Video {
                publicURL
              }
              Embed_Video_URL
            }
          }
        }

        allStrapiNewsCategories(filter: {Publish: {eq: true}}, sort: {fields: Sort, order: ASC}) {
          edges {
            node {
              Category_Name
              URL
              news_events {
                Title
              }
            }
          }
        }

      }
    `}
      render={data => <AllNewsList data={data} parent={props.Parent} page={props.Page}/>}
    />
  )
}