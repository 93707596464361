import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby";
import $ from "jquery"

/**
 * Assets
 */
import blogStyles from "./filter.module.scss"
import Relative from '@components/relative/relative';
import Video from '@components/video/video-modal';
import NoVideo from '@components/video/without-video';
import '../team/assets/styles/_index.scss';


const no_image = "/images/no-team.png"


const getCategories = items => {
  let tempItems = items.map(item => {
    return item.node.Category
  })
  let tempCategories = new Set(tempItems)
  let categories = Array.from(tempCategories)
  categories = ["Everyone", ...categories]
  return categories
}

const MeettheTeam = props => {
  const node = useRef()
  const [items] = useState(props.items)
  const [blogPostItems, setBlogPostItems] = useState(props.items)
  const [categories] = useState(getCategories(props.items))
  const [showItems, setShowItems] = useState(false)
  const [selectedItem, setSelectedItem] = useState(
    getCategories(props.items) &&
      getCategories(props.items)[0]
  )

  const handleItems = Category => {
    let tempItems = [...items]
    if (Category === "Everyone") {
      setBlogPostItems(tempItems)
      setSelectedItem(Category)
    } else {
      let items = tempItems.filter(
        ({ node }) => node.Category === Category
      )
      setBlogPostItems(items)
      setSelectedItem(Category)
    }
    $('html,body').animate({
        scrollTop: $("#team-all").offset().top},
        'slow');
  }

  const dropDown = () => {
    setShowItems(!showItems)
  }

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return
    }
    setShowItems(false)
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick) // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  if (items.length > 0) {
    return (
      <>
            <Container className="team-filter desktop-team-members-filter" id="team-all">
            <div
              ref={node}
              onClick={dropDown}
              className={blogStyles.selectBoxContainer}
            >
                  {categories.map((Category, index) => {
                    const listClass =
                      selectedItem === Category ? `active ${blogStyles.selected}` : ""
                    return (
                      <li
                        key={index}
                        value={Category}
                        onClick={() => handleItems(Category)}
                        className={`btn btn-outline-primary cta ${blogStyles.dropDownItem} ${listClass}`}
                      >
                        {Category.replace(/[^A-Z0-9]+/ig, " ")}
                      </li>
                    )
                  })}
            </div> 
            </Container>


            <Container className="mobile-team-members-filter">
            <p className={blogStyles.filterPosts}>
            <div
              ref={node}
              onClick={dropDown}
              className={blogStyles.selectBoxContainer}
            >
              <span className={blogStyles.selectedBoxItem}>{selectedItem.replace(/[^A-Z0-9]+/ig, " ")}</span>
              <div className={blogStyles.selectBoxArrow}>
                <span
                  className= {`${
                    showItems
                      ? `${blogStyles.selectBoxArrowUp}`
                      : `${blogStyles.selectBoxArrowDown}`
                  }`}
                ></span>
              </div>

              <div
                className={blogStyles.dropDownContainer}
                style={{ display: showItems ? "block" : "none" }}
              >
                <ul className={blogStyles.dropDownList}>
                  {categories.map((Category, index) => {
                    const listClass =
                      selectedItem === Category ? `${blogStyles.selected}` : ""
                    return (
                      <li
                        key={index}
                        value={Category}
                        onClick={() => handleItems(Category)}
                        className={`${blogStyles.dropDownItem} ${listClass}`}
                      >
                        {Category.replace(/[^A-Z0-9]+/ig, " ")}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </p>       
        </Container>
         

          <ul id="Items" className="team-all-items">
            <Container className="team">
                <div className="text-center">
                <Row>
            {blogPostItems.map(({ node }) => {
              if(node.Embed_Video_URL == "" || node.Embed_Video_URL == "#") {
                  var video = "no"
              }
              else
              {
                  var video = "yes"
              }
              return (
              <>
                <Col md={3}>
                    <div className="team__item">
                        <Relative>
                          {video == "yes" && 
                            <Video 
                              image={node.Image} 
                              video={node.Embed_Video_URL}
                              name={node.Name}
                              height="300px" 
                              imgsize="300x300"
                              contain={true}
                            />
                          }
                          {video == "no" &&
                            <NoVideo 
                              image={node.Image} 
                              height="300px" 
                              imgsize="300x300"
                              contain={true}
                            />
                          }
                        </Relative>
                        <div className="team__item-body">
                            <h5>{node.Name}</h5>
                            <p>{node.Designation}</p>
                            <p><a href={`tel:+${node.Phone}`}>{node.Phone}</a> / <Link to="/contact">Email</Link></p>
                        </div>
                    </div>
                    </Col>
                </>
              )
            })}
            </Row>
            </div>
            </Container>
          </ul>

      </>
    )
  } else {
    return (
      <main role="main">
        <div >
          <p>There are no posts to display...please add items</p>
        </div>
      </main>
    )
  }
}

export default MeettheTeam
