import React, {useState} from "react";
import Img from "gatsby-image"

//GGFX
import {importTransformedIamges, getImageTransformedURL} from '@components/common/site/image-transformer-client';

const OfficeCard = (props) => {
  
  const [url, setUrl] = useState('')
  const image_url = props.url.internal.description.replace("File ","").replace('"','').replace('"','').replace(/^.*\/\/[^\/]+/, '').replace(/^\/|\/$/g, '');  

    getImageTransformedURL({
        "srcPath": image_url, 
        //output format 
        "format": 'webp',
        //transformation size
        "transform": '620x210'
      }).then((result) => {
        setUrl(result)
       console.log(result);

      }).catch(error => console.log('ggfx image error', error));

    return (
    <>
        <img src={url ? url : props.url.publicURL} className="office-tile-image" />    
    </>
    );
}

export default OfficeCard;
