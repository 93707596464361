import React, { useState, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import ReactMarkdown from "react-markdown"

import ReapitWiget from '@components/reapit-widget/reapit';
import ValuationImg from '@components/valuation-block/valuation-block-img';

const ValuationBlock = (props) => {

  return (
    <Container className="valuation-block">
      <Row>
      <Col lg="1">
      </Col>
      <Col lg="10">
      <Row>
      {props.data.map((Add, index) => {

        if ((Add.CTA_URL.match('http')) || (Add.CTA_URL.match('https'))) {
        var Link_Type = "external"
        }
        else {
        var Link_Type = "internal"
        }

        return <>

            <Col lg="6">
                <Card className="item valuation-item">
                    {Add.Most_Popular == true &&
                      <div className="ribbon ribbon-top-left"><span className="text-uppercase">Most Popular</span></div>
                    }
                    <ValuationImg url={Add.Image} />
                    <Card.Body>
                        <Card.Title>
                            {Add.Title}
                        </Card.Title>
                        <p className="content"><ReactMarkdown source={Add.Content} /></p>
                        <p className="services"><ReactMarkdown source={Add.Services} /></p>
                        {Link_Type == "external" && Add.CTA_URL != "#reapit" &&
                          <a href={Add.CTA_URL} target="_blank" rel="nofollow" className="primary-button btn btn-primary">{Add.CTA_Label}</a>
                        }
                        {Link_Type == "internal" && Add.CTA_URL != "#reapit" &&
                          <Link to="test" className="primary-button btn btn-primary">{Add.CTA_Label}</Link>
                        }
                        {Add.CTA_URL == "#reapit" &&
                        <ReapitWiget class="primary-button btn btn-primary reapit-val-btn" label={Add.CTA_Label}/>
                        }
                    </Card.Body>
                </Card>
            </Col>

        </>
      })}
      </Row>
      </Col>
      <Col lg="1">
      </Col>
      </Row>
    </Container>
  )
}

export default ValuationBlock;
