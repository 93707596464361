import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"
import classNames from 'classnames'


/**
 * Assets
 */
import './assets/styles/_index.scss';

/**
 * Components
 */
import Relative from '@components/relative/relative';
import Video from '@components/video/video-modal';
import NoVideo from '@components/video/without-video';

function LinkRenderer(props) {
    if (props.href.match('http')) {
    return <a href={props.href} target="_blank" rel="nofollow">{props.children}</a>
    }
    if (props.href.match('https')) {
    return <a href={props.href} target="_blank" rel="nofollow">{props.children}</a>
    }
    return <Link to={`/${props.href}`}>{props.children}</Link>
}

const Team = (props) => {
    const [hover, setHover] = useState('');
    const handleMouseEnter = (e) => {
        if (e.target.classList.value === 'team__item' || e.target.closest('.team__item')){
            const ele = e.target.classList.value === 'team__item' ? e.target : e.target.closest('.team__item');
            setHover(ele.getAttribute('data-item'))
        }
    };
    const handleMouseLeave = () => setHover('');

    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              autoplaySpeed: 500,
              variableWidth: false,
              autoplay: false,
              centerMode: false,
              cssEase: 'ease',
              speed: 1000
          }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                autoplaySpeed: 500,
                variableWidth: false,
                autoplay: false,
                centerMode: false,
                cssEase: 'ease',
                speed: 1000
            }
        },
      ]
    };

    return (
        <Container className="team team-slide">
            <div 
                className="team text-center" 
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
            >
                <h4>{props.Title}</h4>
                <p><ReactMarkdown source={props.Content} renderers={{link: LinkRenderer}}/></p>

                <Slider {...settings}>


                {props.data.map((Teams, index) => {
                    if((Teams.Embed_Video_URL == "") || (Teams.Embed_Video_URL == "#")) {
                        var video = "no"
                    }
                    else
                    {
                        var video = "yes"
                    }

                    var i = index
                  return (
                    <>
                    <div className={classNames('team__item', {"opacity": hover !== `${index}` && hover !== ""})} data-item={index} onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <Relative>
                        {video == "yes" && 
                            <Video 
                              image={Teams.Image} 
                              video={Teams.Embed_Video_URL}
                              name={Teams.Name}
                              height="300px" 
                              imgsize="300x300"
                              contain={true}
                            />
                          }
                          {video == "no" &&
                            <NoVideo 
                              image={Teams.Image} 
                              height="300px" 
                              imgsize="300x300"
                              contain={true}
                            />
                          }
                        </Relative>
                        <div className="team__item-body">
                            <h5>{Teams.Name}</h5>
                            <p>{Teams.Designation}</p>
                            <p><a href={`tel:+${Teams.Phone}`}>{Teams.Phone}</a> / <Link to="/contact">Email</Link></p>
                        </div>
                    </div>
                    </>
                  )
                })}
                </Slider>
            </div>
        </Container>
    )
}

export default Team
